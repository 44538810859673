var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var prefix = "far";
var iconName = "envelope";
var width = 512;
var height = 512;
var aliases = [128386, 9993, 61443];
var unicode = "f0e0";
var svgPathData = "M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z";
exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, aliases, unicode, svgPathData]
};
exports.faEnvelope = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;
export default exports;
export const __esModule = exports.__esModule,
  definition = exports.definition,
  faEnvelope = exports.faEnvelope,
  ligatures = exports.ligatures;
const _prefix = exports.prefix,
  _iconName = exports.iconName,
  _width = exports.width,
  _height = exports.height,
  _unicode = exports.unicode,
  _svgPathData = exports.svgPathData,
  _aliases = exports.aliases;
export { _prefix as prefix, _iconName as iconName, _width as width, _height as height, _unicode as unicode, _svgPathData as svgPathData, _aliases as aliases };